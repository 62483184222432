/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Subtitle, SeparateLine, SeparateLineWrap, Slideshow, Text, Image } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Úvodní stránka"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-4cbfai js-anim  --anim2 --center" style={{"paddingTop":2,"marginBottom":1,"paddingBottom":26,"marginTop":0}} name={"bm524ic9w6"} parallax={false} anim={"2"} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/37992/08d4f4be92d140c79404976bd4bd010e_s=350x_.png);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/37992/08d4f4be92d140c79404976bd4bd010e_s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/37992/08d4f4be92d140c79404976bd4bd010e_s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/37992/08d4f4be92d140c79404976bd4bd010e_s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/37992/08d4f4be92d140c79404976bd4bd010e_s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/37992/08d4f4be92d140c79404976bd4bd010e_s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/37992/08d4f4be92d140c79404976bd4bd010e_s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/37992/08d4f4be92d140c79404976bd4bd010e_s=3000x_.png);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left" style={{"paddingTop":74}}>
              
              <Title className="title-box title-box--center fs--43" style={{"paddingBottom":0,"marginTop":22,"paddingTop":7}} content={"<span style=\"background-color: rgb(255, 255, 255);\">Vítáme vás na stránkách divadelní agentury ADF</span>"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--center fs--30" style={{"marginTop":0,"paddingTop":0,"marginBottom":0,"paddingBottom":10}} content={"<span style=\"font-style: italic; background-color: rgb(255, 255, 255);\">...každé představení je zážitkem...</span>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine style={{"paddingTop":2,"paddingBottom":6,"marginBottom":0}} name={"aduqyvexp9s"} layout={"l6"}>
          
          <SeparateLineWrap style={{"marginTop":2,"paddingBottom":5}}>
          </SeparateLineWrap>

        </SeparateLine>


        <Slideshow style={{"paddingTop":0,"marginTop":3}} name={"ye1gmwo9s8m"} autoplay={true} infinite={true} indicators={"both"} transitionDuration={6} effect={"fade"}>
          
          <Column className="js-anim  --anim2" style={{"marginTop":0,"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(255, 255, 255, 1)"}} lightbox={true} anim={"2"}>
            
            <ColumnWrap className="column__flex --center el--2 flex--center" style={{"paddingTop":0,"marginBottom":0}} columns={"2"} fullscreen={false}>
              
              <ColumnWrapper className="--left pl--40">
                
                <Subtitle className="subtitle-box" content={"<span style=\"font-style: italic;\"><a href=\"/tak-ja-letim\">Vše se dá přežít s nadhledem, sebeironií a humorem</a></span>"}>
                </Subtitle>

                <Title className="title-box" content={"<a href=\"/tak-ja-letim\">Tak já letim!</a>"}>
                </Title>

                <Subtitle className="subtitle-box" content={"<a href=\"/tak-ja-letim\">Peter Quilter<br>Překlad: Pavel Dominik<br>Režie: Petr Svojtka <br>Hraje: Anna Polívková</a><br>"}>
                </Subtitle>

                <Text className="text-box fs--16" content={"<span style=\"font-style: italic; color: var(--color-blend--50);\">Foto: Jerry Háša</span>"}>
                </Text>

              </ColumnWrapper>

              <ColumnWrapper className="--left">
                
                <Image style={{"maxWidth":536}} alt={""} src={"https://cdn.swbpg.com/t/37992/c0efb57d02ac4f4cbeb0ed803f8046d0_s=860x_.jpg"} use={"page"} href={"/tak-ja-letim"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/37992/c0efb57d02ac4f4cbeb0ed803f8046d0_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37992/c0efb57d02ac4f4cbeb0ed803f8046d0_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37992/c0efb57d02ac4f4cbeb0ed803f8046d0_s=860x_.jpg 860w, https://cdn.swbpg.com/t/37992/c0efb57d02ac4f4cbeb0ed803f8046d0_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/37992/c0efb57d02ac4f4cbeb0ed803f8046d0_s=2000x_.jpg 2000w"} lightbox={false}>
                </Image>

              </ColumnWrapper>

            </ColumnWrap>

          </Column>

          <Column style={{"backgroundColor":"rgba(255, 255, 255, 1)"}} lightbox={true}>
            
            <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
              
              <ColumnWrapper className="--left pl--40">
                
                <Subtitle className="subtitle-box" content={"<span style=\"font-style: italic;\"><a href=\"/madame-rubinstein\">Neexistují ošklivé ženy, pouze líné</a></span><br>"}>
                </Subtitle>

                <Title className="title-box" content={"<a href=\"/madame-rubinstein\">Madame Rubinstein</a>"}>
                </Title>

                <Subtitle className="subtitle-box" content={"<a href=\"/madame-rubinstein\">John Misto<br>Překlad: Benjamin Kuras<br>Režie: Petr Svojtka <br>Hrají: Dana Syslová, Milena Steinmasslová a Jiří Hána</a><br>"}>
                </Subtitle>

                <Text className="text-box fs--16" content={"<span style=\"font-style: italic; color: var(--color-blend--50);\">Foto: Václav Beran</span>"}>
                </Text>

              </ColumnWrapper>

              <ColumnWrapper className="--left">
                
                <Image style={{"maxWidth":536}} alt={""} src={"https://cdn.swbpg.com/t/37992/61e8f1d2803a4ba5817f7453ccb10499_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/37992/61e8f1d2803a4ba5817f7453ccb10499_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37992/61e8f1d2803a4ba5817f7453ccb10499_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37992/61e8f1d2803a4ba5817f7453ccb10499_s=860x_.jpg 860w, https://cdn.swbpg.com/t/37992/61e8f1d2803a4ba5817f7453ccb10499_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/37992/61e8f1d2803a4ba5817f7453ccb10499_s=2000x_.jpg 2000w"} lightbox={false} use={"page"} href={"/madame-rubinstein"}>
                </Image>

              </ColumnWrapper>

            </ColumnWrap>

          </Column>

          <Column style={{"backgroundColor":"rgba(255, 255, 255, 1)"}} lightbox={true}>
            
            <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
              
              <ColumnWrapper className="--left pl--40">
                
                <Subtitle className="subtitle-box" content={"<span style=\"font-style: italic;\"><a href=\"/treti-prst\">Hořkosladký životní příběh o bezpodmínečné lásce, odpuštění a tanci</a></span><br>"}>
                </Subtitle>

                <Title className="title-box" content={"<a href=\"/treti-prst\">Třetí prst na levé ruce</a>"}>
                </Title>

                <Subtitle className="subtitle-box" content={"<a href=\"/treti-prst\">Dermot Canavan<br>Překlad: Jan a Adéla Šotolovi<br>Režie: Martin Vokoun a Adéla Šotolová<br>Hrají: Iva Pazderková a Martina Randová</a><br>"}>
                </Subtitle>

                <Text className="text-box fs--16" content={"<span style=\"font-style: italic; color: var(--color-blend--50);\">Foto: Václav Beran</span>"}>
                </Text>

              </ColumnWrapper>

              <ColumnWrapper >
                
                <Image style={{"maxWidth":536}} alt={""} src={"https://cdn.swbpg.com/t/37992/46d006b9ba6a4ed5b95d3cbae85de836_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/37992/46d006b9ba6a4ed5b95d3cbae85de836_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37992/46d006b9ba6a4ed5b95d3cbae85de836_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37992/46d006b9ba6a4ed5b95d3cbae85de836_s=860x_.jpg 860w, https://cdn.swbpg.com/t/37992/46d006b9ba6a4ed5b95d3cbae85de836_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/37992/46d006b9ba6a4ed5b95d3cbae85de836_s=2000x_.jpg 2000w"} lightbox={false} use={"page"} href={"/treti-prst"}>
                </Image>

              </ColumnWrapper>

            </ColumnWrap>

          </Column>

          <Column style={{"backgroundColor":"rgba(255, 255, 255, 1)"}} lightbox={true}>
            
            <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
              
              <ColumnWrapper className="--left pl--40">
                
                <Subtitle className="subtitle-box" content={"<span style=\"font-style: italic;\"><a href=\"/shirley-valentine\">Život je potřeba žít naplno a nikdy není pozdě ho změnit</a></span><br>"}>
                </Subtitle>

                <Title className="title-box" content={"<a href=\"/shirley-valentine\">Shirley Valentine</a><br>"}>
                </Title>

                <Subtitle className="subtitle-box" content={"Willy Russell\n<br>Překlad: Pavel Dominik\n<br>Režie: Zdeněk Kaloč\n<br>Hraje: Simona Stašová<br>"}>
                </Subtitle>

                <Text className="text-box fs--16" content={"<span style=\"font-style: italic; color: var(--color-blend--50);\">Foto: Alena Hrbková</span>"}>
                </Text>

              </ColumnWrapper>

              <ColumnWrapper >
                
                <Image style={{"maxWidth":536}} alt={""} src={"https://cdn.swbpg.com/t/37992/3e26121f245745daa04746906fd57478_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/37992/3e26121f245745daa04746906fd57478_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37992/3e26121f245745daa04746906fd57478_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37992/3e26121f245745daa04746906fd57478_s=860x_.jpg 860w, https://cdn.swbpg.com/t/37992/3e26121f245745daa04746906fd57478_s=1400x_.jpg 1400w"} lightbox={false} use={"page"} href={"/shirley-valentine"}>
                </Image>

              </ColumnWrapper>

            </ColumnWrap>

          </Column>

          <Column style={{"backgroundColor":"rgba(255, 255, 255, 1)"}} lightbox={true}>
            
            <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
              
              <ColumnWrapper className="--left pl--40">
                
                <Subtitle className="subtitle-box" content={"<span style=\"font-style: italic;\"><a href=\"/muj-bajecny-rozvod\">Laskavá komedie věnovaná všem, kteří ne vlastní vinou zůstali sami</a></span><br>"}>
                </Subtitle>

                <Title className="title-box" content={"<a href=\"/muj-bajecny-rozvod\">Můj báječný rozvod</a><br>"}>
                </Title>

                <Subtitle className="subtitle-box" content={"Geraldine Aron\n<br>Překlad: Pavel Dominik\n<br>Režie: Jana Kališová\n<br>Hraje: Eliška Balzerová<br>"}>
                </Subtitle>

                <Text className="text-box fs--16" content={"<span style=\"font-style: italic; color: var(--color-blend--50);\">Foto: Roman Černý</span>"}>
                </Text>

              </ColumnWrapper>

              <ColumnWrapper >
                
                <Image style={{"maxWidth":536}} alt={""} src={"https://cdn.swbpg.com/t/37992/4a655a8a5f3e4adbaf316accf612825c_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/37992/4a655a8a5f3e4adbaf316accf612825c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37992/4a655a8a5f3e4adbaf316accf612825c_s=660x_.jpg 660w"} lightbox={false} use={"page"} href={"/muj-bajecny-rozvod"}>
                </Image>

              </ColumnWrapper>

            </ColumnWrap>

          </Column>

        </Slideshow>


        <SeparateLine style={{"paddingTop":5,"paddingBottom":2}} name={"qtr0g4bgcr"} layout={"l6"}>
          
          <SeparateLineWrap style={{"marginTop":7,"paddingBottom":2}}>
          </SeparateLineWrap>

        </SeparateLine>


        <SeparateLine name={"yta72c41gv"} layout={"l6"}>
        </SeparateLine>


        <Column className="css-kwifmb pt--60" style={{"paddingBottom":84}} name={"jf6e3cuccgl"} parallax={false} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/37992/f5e564177147449190034728cf75500b_s=350x_.png);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/37992/f5e564177147449190034728cf75500b_s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/37992/f5e564177147449190034728cf75500b_s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/37992/f5e564177147449190034728cf75500b_s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/37992/f5e564177147449190034728cf75500b_s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/37992/f5e564177147449190034728cf75500b_s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/37992/f5e564177147449190034728cf75500b_s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/37992/f5e564177147449190034728cf75500b_s=3000x_.png);
    }
  
    `}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --center el--2 flex--center" columns={"2"} anim={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37992/6382902e5db84bb88b617106165aff36_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":510}} srcSet={"https://cdn.swbpg.com/t/37992/6382902e5db84bb88b617106165aff36_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37992/6382902e5db84bb88b617106165aff36_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37992/6382902e5db84bb88b617106165aff36_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper style={{"backgroundColor":"var(--white)"}}>
              
              <Title className="title-box" content={"Připravujeme nový titul"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":399}} content={"Milí diváci,\n<br>právě pro vás zkoušíme novou inscenaci s názvem <span style=\"font-weight: bold;\">HODINY S RITOU</span> – komedii jednoho z nejlepších britských dramatiků Willyho Russella v překladu Pavla Dominika. Těšit se můžete na vynikající herce <span style=\"font-weight: bold;\">Kateřinu Marii Fialovou</span> a <span style=\"font-weight: bold;\">Vasila Fridricha</span> v režii <span style=\"font-weight: bold;\">Martina Vokouna</span>.\nPremiéru plánujeme na 13. února 2025 na Malé scéna divadla Studio DVA."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine style={{"paddingTop":5,"paddingBottom":2}} name={"qtr0g4bgcr"} layout={"l6"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="css-1h0wug0 pt--60" style={{"paddingBottom":63}} name={"au9134xnde4"} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/37992/3435a23716e24ab6ba57dc81742e453c_s=350x_.png);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/37992/3435a23716e24ab6ba57dc81742e453c_s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/37992/3435a23716e24ab6ba57dc81742e453c_s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/37992/3435a23716e24ab6ba57dc81742e453c_s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/37992/3435a23716e24ab6ba57dc81742e453c_s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/37992/3435a23716e24ab6ba57dc81742e453c_s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/37992/3435a23716e24ab6ba57dc81742e453c_s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/37992/3435a23716e24ab6ba57dc81742e453c_s=3000x_.png);
    }
  
    `}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --center el--2 flex--center" columns={"2"} anim={"2"}>
            
            <ColumnWrapper style={{"backgroundColor":"var(--white)"}}>
              
              <Title className="title-box" content={"Úspěchy Madame Rubinstein"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":399}} content={"Inscenace<span style=\"font-weight: bold;\"> <a href=\"/madame-rubinstein\" style=\"color: var(--color-blend--50);\">MADAME RUBINSTEIN</a></span> v režii Petra Svojtky a v titulní roli s Danou Syslovou získala 1. místo na Festivalu divadla 2-3-4 herců v kategorii Cena diváků. \nV roce 2019 jsme také byli s touto inscenací pozváni do Jeruzaléma na International Jewish Festival of the Performing Arts."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37992/3559b2c879d1475387a7d58fd15b1d03_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":510}} srcSet={"https://cdn.swbpg.com/t/37992/3559b2c879d1475387a7d58fd15b1d03_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37992/3559b2c879d1475387a7d58fd15b1d03_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37992/3559b2c879d1475387a7d58fd15b1d03_s=860x_.jpg 860w, https://cdn.swbpg.com/t/37992/3559b2c879d1475387a7d58fd15b1d03_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine style={{"marginTop":1,"marginBottom":0,"paddingBottom":1,"paddingTop":1}} name={"cy8vo8j64da"} layout={"l6"}>
          
          <SeparateLineWrap style={{"marginBottom":1}}>
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="js-anim  --anim2 --center" style={{"marginTop":0,"paddingTop":5,"paddingBottom":0}} name={"r8nodztltl"} anim={"2"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" style={{"maxWidth":632,"marginTop":3,"paddingLeft":0,"marginBottom":0,"paddingRight":0,"paddingBottom":3}} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":400,"paddingTop":0,"paddingLeft":0,"paddingBottom":2}}>
              
              <Title className="title-box title-box--left fs--16 w--500 ls--001 lh--14" style={{"maxWidth":450,"paddingLeft":3,"paddingRight":0}} content={"<span style=\"color: var(--color-blend--15);\">ADF | agentura divadelní fantazie<br>Kateřina Schauerová&nbsp;<br>Marek Sucharda</span><br>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper style={{"marginTop":0,"paddingTop":0,"paddingBottom":1}}>
              
              <Text className="text-box text-box--left fs--16 lh--14" style={{"maxWidth":503,"paddingLeft":0,"paddingRight":0}} content={"<span style=\"color: var(--color-custom-1--15);\"><a href=\"mailto:adf@adf.cz?subject=Zpr%C3%A1va+z+webu\">adf@adf.cz</a> | <a href=\"/\">www.adf.cz</a><br>+420 603 219 657 | <a href=\"mailto:katerina@adf.cz?subject=Zpr%C3%A1va+z+webu\">katerina@adf.cz</a><br>+420 775 262 617 | <a href=\"mailto:marek@adf.cz?subject=Zpr%C3%A1va+z+webu\">marek@adf.cz</a></span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left" style={{"paddingTop":2}}>
              
              <Text className="text-box text-box--center fs--12" content={"© 2024 ADF | Kateřina Schauerová, Marek Sucharda"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}